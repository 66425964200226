import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { editSystem } from '../../../api/systems';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import CommonHead from '../../../components/commonHead';
import Button from '../../../components/ui/button';
import ButtonLink from '../../../components/ui/buttonLink';
import Checkbox from '../../../components/ui/checkbox';
import Input from '../../../components/ui/input';
import {
  IInputTAdressValue,
  IInputValue,
  InputStatus,
  InputType,
  defaultAddressValue,
  defaultNotRequiredValue,
} from '../../../components/ui/input/types';
import { getObjectSeriesUrl, getObjectSystemUrl, organizationUrl } from '../../../constants/api';
import { paths } from '../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { setHeaderTitle } from '../../../store/slices/header';
import { ESystemTabErrorCodes } from '../../../api/systems/types';
import { ESystemTabsIds } from '../item/types';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getWasChange } from '../../../store/selectors/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import { setChange } from '../../../store/slices/changes';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { defaultConfirm, saveChangesModal } from '../../../components/ui/universalModal/config';
import { IConfirmData } from '../../../components/ui/universalModal/types';
import UniversalModal from '../../../components/ui/universalModal';
import InputDaData from '../../../components/ui/inputDaData';
import { getSettingsInStore } from '../../../store/selectors/profile';
import Select from '../../../components/ui/select';
import { ButtonType } from '../../../components/ui/button/types';
import { getRequest } from '../../../api';
import { ESystemBaseDeviceSeries, ESystemDeviceMode, ISystemDevice } from '../../../typings/systems/base';
import { IOrganization } from '../../../typings/organization';

const errorText = 'Поле обязательно для заполнения';

const SystemsNew: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sendRequest, loading } = useApi(editSystem);

  const { data: devices, sendRequest: getDevices } = useApi<ISystemDevice[]>(getRequest);

  const { data: company, sendRequest: getCompany } = useApi<IOrganization>(getRequest);

  const settings = useAppSelector(getSettingsInStore);
  const wasChange = useAppSelector(getWasChange);

  const [systemName, setSystemName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [systemAddressData, setSystemAddressData] = useState<IInputTAdressValue>({ ...defaultAddressValue });
  const [systemShortName, setSystemShortName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [systemSeries, setSystemSeries] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [keyMode, setKeyMode] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [isItvEnabled, setItvEnabled] = useState(false);
  const [globalNetPassword, setGlobalNetPassword] = useState<IInputValue>({ ...defaultNotRequiredValue });

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  useEffect(() => {
    dispatch(setHeaderTitle('Создание объекта'));
    getDevices(getObjectSeriesUrl());
    getCompany(organizationUrl());
  }, []);

  const goBack = useCallback(() => {
    if (wasChange) {
      dispatch(setClickedSidebarTab(paths.systems));
    } else {
      navigate(paths.systems);
    }
  }, [dispatch, navigate, wasChange]);

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: any) => {
      setter({ value, errorText: '', status: InputStatus.normal });
      dispatch(setChange(true));
    },
    [dispatch]
  );

  const onChangeDaData = useCallback(
    (data: IInputTAdressValue) => {
      setSystemAddressData(data);
      dispatch(setChange(true));
    },
    [dispatch]
  );

  const validateInputs = useCallback(() => {
    let isValid = true;

    if (!systemName.value || !systemName.value.trim()) {
      setSystemName({ ...systemName, errorText, status: InputStatus.error });
      isValid = false;
    }

    if (!systemShortName.value || !systemShortName.value.trim()) {
      setSystemShortName({ ...systemShortName, errorText, status: InputStatus.error });
      isValid = false;
    }

    if (settings.category5000 && !systemSeries.value) {
      setSystemSeries({ ...systemSeries, errorText, status: InputStatus.error });
      isValid = false;
    }

    if (
      systemSeries.value === ESystemBaseDeviceSeries.D5000 &&
      !globalNetPassword.value &&
      globalNetPassword.value !== 0
    ) {
      setGlobalNetPassword({ ...globalNetPassword, errorText, status: InputStatus.error });
      isValid = false;
    }

    if (!keyMode.value) {
      setKeyMode({ ...keyMode, errorText, status: InputStatus.error });
      isValid = false;
    }

    if (systemAddressData.status !== InputStatus.normal) {
      isValid = false;
    }

    return isValid;
  }, [
    globalNetPassword,
    keyMode,
    settings.category5000,
    systemAddressData.status,
    systemName,
    systemSeries,
    systemShortName,
  ]);

  const handleButtonOnClick = useCallback(async () => {
    const newSystem: any = {
      objectName: systemName.value,
      shortName: systemShortName.value,
      address: systemAddressData.address,
      withSecurityDesk: true,
      deviceSeriesId: systemSeries.value,
      secureKeyMode: keyMode.value,
      isItvEnabled: isItvEnabled,
    };

    if (systemSeries.value === ESystemBaseDeviceSeries.D5000) newSystem.globalNetPassword = globalNetPassword.value;

    if (settings.daDataSearch) {
      newSystem.fiasId = systemAddressData.fiasId;
    }

    const resError = await sendRequest(getObjectSystemUrl(), newSystem);

    const resData = resError?.response?.data;
    const newSystemId = resData ? '' : resError;
    if (newSystemId) {
      if (clickedSidebarTab) {
        return true;
      }
      navigate({
        pathname: `${paths.systems}/${newSystemId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESystemTabsIds.basic })}`,
      });
    } else if (resData?.errorCodes === ESystemTabErrorCodes.ObjectDuplicateName) {
      setSystemName({ ...systemName, status: InputStatus.error, errorText: resData.message });
    } else if (resData?.errorCodes === ESystemTabErrorCodes.ObjectDuplicateShortName) {
      setSystemShortName({
        ...systemShortName,
        status: InputStatus.error,
        errorText: resData.message,
      });
    }
  }, [
    systemName,
    systemShortName,
    systemAddressData.address,
    systemAddressData.fiasId,
    systemSeries.value,
    keyMode.value,
    globalNetPassword.value,
    settings.daDataSearch,
    sendRequest,
    clickedSidebarTab,
    navigate,
  ]);

  const tryToSave = useCallback(
    async (callBack = () => {}) => {
      if (validateInputs()) {
        if (settings.category5000) {
          setConfirmData({
            isOpen: true,
            description:
              'После создания объекта вы не сможете изменить серию оборудования и режим работы с ключами. Вы уверены, что хотите создать объект?',
            buttons: [
              {
                label: 'Да',
                type: ButtonType.primary,
                onClick: async () => {
                  closeConfirm();
                  const result = await handleButtonOnClick();
                  if (result && callBack) {
                    callBack();
                  }
                },
              },
              {
                label: 'Нет',
                type: ButtonType.secondary,
                onClick: closeConfirm,
              },
            ],
          });
        } else {
          const result = await handleButtonOnClick();
          if (result && callBack) {
            callBack();
          }
        }
      }
    },
    [closeConfirm, handleButtonOnClick, settings.category5000, validateInputs]
  );

  const checkChanges = useCallback(
    (callBack = () => {}) => {
      if (wasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              tryToSave(callBack);
            },
            () => {
              callBack();
              closeConfirm();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, tryToSave, wasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        dispatch(setChange(false));
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const devicesOptions = useMemo(
    () =>
      devices?.map((item) => ({
        value: item.id,
        title: item.displayName,
      })),
    [devices]
  );

  const isItvAllowed = useMemo(() => company?.isItvEnabled, [company]);

  return (
    <>
      <CommonHead seo={{ title: 'Создание объекта' }} />
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="systems-new">
        <div className="systems-new__button-wrapper">
          <ButtonLink withCallback onClick={goBack} leftIcon={<ArrowBackIcon />} content="Все объекты" />
        </div>
        <div className="systems-new__content">
          <div className="systems-new__input-container">
            <Input
              title="Наименование"
              value={systemName.value}
              status={systemName.status}
              errorText={systemName.errorText}
              onChange={(value) => handleOnChangeInput(setSystemName, value)}
              placeholder="Наименование"
              isRequired
              maxLength={400}
              inputClassName="systems-new__input"
            />
            <Input
              title="Краткое наименование"
              value={systemShortName.value}
              status={systemShortName.status}
              errorText={systemShortName.errorText}
              onChange={(value) => handleOnChangeInput(setSystemShortName, value)}
              placeholder="Краткое наименование"
              isRequired
              maxLength={55}
              inputClassName="systems-new__input"
            />
          </div>
          <div className="systems-new__input-container">
            <InputDaData
              title="Адрес (город, улица, дом),"
              daDataSearch={settings.daDataSearch}
              onChange={onChangeDaData}
              value={systemAddressData}
            />
            <div className="systems-new__selects-container">
              <Select
                title="Серия оборудования"
                textInfo="После создания объекта вы не сможете изменить серию оборудования"
                isRequired
                value={systemSeries.value}
                isError={systemSeries.status === InputStatus.error}
                errorText={systemSeries.errorText}
                onChange={(value) => {
                  handleOnChangeInput(setSystemSeries, value.toString());
                  handleOnChangeInput(setKeyMode, '');
                }}
                options={devicesOptions}
              />
              <Select
                title="Режим работы с ключами"
                isRequired
                disabled={!systemSeries.value}
                value={keyMode.value}
                isError={keyMode.status === InputStatus.error}
                errorText={keyMode.errorText}
                onChange={(value) => handleOnChangeInput(setKeyMode, value.toString())}
                textInfo="После создания объекта вы не сможете изменить режим работы с ключами"
                options={
                  settings.notEmfKeys
                    ? systemSeries.value === ESystemBaseDeviceSeries.D5000
                      ? [
                          {
                            value: ESystemDeviceMode.miFare,
                            title: 'Mifare',
                          },
                          {
                            value: ESystemDeviceMode.emMarin,
                            title: 'EM-Marin',
                          },
                          {
                            value: ESystemDeviceMode.EMF,
                            title: 'EMF',
                          },
                        ]
                      : [
                          {
                            value: ESystemDeviceMode.EMF,
                            title: 'EMF',
                          },
                          {
                            value: ESystemDeviceMode.miFare,
                            title: 'Mifare',
                          },
                        ]
                    : [
                        {
                          value: ESystemDeviceMode.EMF,
                          title: 'EMF',
                        },
                      ]
                }
              />
            </div>
            {isItvAllowed && (
              <div className="systems-new__input-container">
                <Checkbox
                  label="Использование системы ITV"
                  checked={isItvEnabled}
                  valueKey="isItvEnabled"
                  onChange={(value) => setItvEnabled(value)}
                />
              </div>
            )}
          </div>
          {systemSeries.value === ESystemBaseDeviceSeries.D5000 && (
            <div className="systems-new__input-container">
              <Input
                title="Пароль сети объекта"
                value={globalNetPassword.value}
                status={globalNetPassword.status}
                errorText={globalNetPassword.errorText}
                onChange={(value) => handleOnChangeInput(setGlobalNetPassword, value === '' ? '' : Number(value))}
                placeholder="Пароль сети объекта"
                isRequired
                inputType={InputType.number}
                maxLength={8}
                inputClassName="systems-new__input"
              />
            </div>
          )}
        </div>
        <div className="systems-new__button-container">
          <Button
            className="systems-new__button"
            disabled={!wasChange || !systemAddressData.address.trim() || systemAddressData.status === InputStatus.error}
            onClick={tryToSave}
            loading={loading}
          >
            Создать
          </Button>
        </div>
      </div>
    </>
  );
};

export default SystemsNew;
